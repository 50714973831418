.text-new-dark{
  color: #495057;
}
.text-semi-dark{
  color: #74788D;
}
.text-new-primary{
  color: #00b383b3;
}
.text-semi-dark-svg path{
  fill: #74788D;
}
.text-new-primary-svg path{
  fill: #556EE6;
}
.text-success-svg path{
  fill: #34c38f;
}
.text-warning-svg path{
  fill: #f1b44c;
}
.text-danger-svg path{
  fill: #f46a6a;
}
.text-new-info {
  color: #00b383b3;
}
.text-new-warning {
  color: #f1b44c;
}
.text-new-danger {
  color: #f46a6a;
}
body{
  overflow-y: auto;
}
*{
  @extend .text-new-dark
}
.layout-container{
  padding: 0 35px;
  background-color:#F8F9FA;
  min-height:100vh;
}

.header-container{
  height: 50px;
  background-color: #2A3042;
  color: #fff;
  //@media (min-width: 768px) {
    width: calc(100% + 94px);
    margin-left: -47px;
  //}
}

.footer-container{
  height: 50px;
  background-color: #F2F2F5;
  color: #74788D;
  width: calc(100% + 94px);
  margin-left: -47px;
}

.navigation-container{
  height: 50px;
  background-color: #fff;
  color: #74788D;
  width: calc(100% + 94px);
  margin-left: -47px;
  i {
    padding-top:2px ;
  }
  div, i{
    color: #74788D !important;
  }

  .text-primary{
    div, i{
      color: $primary !important;
    }
  }
  a{
    text-decoration: none;
  }
  >div {
    overflow-x: auto;
    a, >div{
      min-width: max-content;
      @media screen and (min-width: 768px) {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: 100px;
    >div {
      >div {
        overflow-x: auto;
        margin-top: 10px;
      }
      a{
        min-width: max-content;
      }
    }
  }
}

.login-container{
  padding:0;
  margin:0;
  max-width:100%;
  position:absolute;
  width:100vw;
  margin-left: -35px;
}

.index-container-wrapper{
  @media (min-width: 478px) {
    display: flex;
    align-items: center;
    min-height: 100vh;
    .index-container{
      border: 1px solid #707070;
      box-shadow: 0px 3px 6px #00000029;
    }
  }
  .index-container{
    max-width: 478px;
    padding: 0 0 30px;
    a{
      @extend .btn-primary, .row;
      justify-content: center;
      padding: 25px;
      margin: 30px auto;
      cursor: pointer;
      width: 90%;
      text-decoration: none;
    }
    div:first-child{
      @extend .bg-black, .btn-primary;
      text-align: center;
      padding: 35px 0px;
      cursor: default;
      margin-bottom: 40px;
    }
  }
}

.data-row-1{
  display: flex;
  padding: 20px;
  border: #F8F9FA 1px solid;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  div:first-child{
    display: flex;
    align-items: center;
    div:nth-child(2){
      div:first-child{
        font-weight: bold;
      }
  
      div:last-child{
        font-size: 11px;
        color: #74788D;
      }
    }
  }
  
  > div:nth-child(2) {
    font-weight: bold;
  }
}



.searchbar-container{
  .searchbar-input{
    max-width: 280px;
    position: relative;
    input {
      padding-left: 40px;
      border-radius: 20px;
    }
    .bx-search-alt{
      position: absolute;
      left: 20px;
      top: 8px;
    }
  }
  .btn{
    display: flex;
    align-items: center;
    margin-left: auto;
    border-radius: 20px;
  }
  a{
    text-decoration: none !important;
  }
}


.table-container {
  margin-top: 40px;
  position:relative;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    overflow-X:auto;
  }
  table{
    tr{
      th{
        background: #F8F9FA;
      }
      td{
        vertical-align: middle;
        button{
          padding: 5px 10px !important;
        }
      }
      td:last-child{
        white-space: nowrap;
      }
    }
  }
  .react-bootstrap-table-pagination{
    position: absolute;
    right: 0;
    left: 0;
  }
}

.table-container-table1{
  table{
    min-width:600px;
    th{white-space:nowrap}
  }
}

.system-table-container-plt{
  overflow: auto;
  table{
    min-width:1200px;
  }
}
.system-table-container{
  table{
    min-width:800px;
  }
}

.header-less-table-container{
  margin-top: -10px;
  table{
    border-collapse:separate;
    border-spacing:0 15px;
  }
  tr,td {
    border: #F8F9FA 1px solid;
    padding: 25px;
  }
  tr{
    td:first-child{
      border-radius: 8px 0px 0px 8px;
      border-right: none;
    }

    td:last-child{
      border-radius: 0px 8px 8px 0px;
      border-left: none;
    }
  }

  thead, tbody, tfoot, thead tr th {
    border: none;
  }

  thead{
    display: none;
  }
}


.react-bootstrap-table-pagination-list{
  width:100%;
  margin-top: 10px;
  ul{
    justify-content:center;
  }
  .pagination {
    justify-content: flex-end;
    margin-right: 20px;
    .page-item {
      padding:4px 0;
      border-radius: 20px;
      width: 34px;
      justify-content: center;
      display: flex;
      height: fit-content;
      .page-link{
        background-color: transparent !important;
        border:none !important;
        border-radius:20px;
        box-shadow: none !important;
        color: #495057;
        font-size:14px;
        line-height:1;
        margin:auto;
      }
      &:first-child, &:last-child{
        a{
          font-size: 20px;
          margin-top: -3px;
        }
      }
    }
    .active{
      background-color: $primary !important;
      .page-link{
        color: #fff;
      }
    }
  }
}




.form-btn-grp{
  .btn{
    padding-left: 25px;
    padding-right: 25px;
  }
}



.view-entity-container{
  div{
    margin-top: 5px;
  }
  .form-control{
    color: $gray-600;
    background: $gray-200;
    margin-bottom: 10px;
    min-height: 37px;
  }
}


.file-drop{
  cursor:pointer;
  border: 1px dashed #CED4DA;
  height:170px;
  border-radius:5px;
  path{
    fill: $secondary;
  }
}
.file-drop-target{
  width: 100%;
  height: 100%;
  text-align:center;
  display:flex;
  div{
    pointer-events:none;
    align-items:center;
    margin:auto
  }
}
.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px #f4f4f4;
  background:#f4f4f4 !important;
}


.search-select-dropdown{
  display: block;
  width: 100%;
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.25rem center;
  background-size: 16px 12px;
}


.paths-container{
  i{
    font-size:1.3rem;
  }
}

.form-limitation-container{
  position: relative;
  div{
    position: absolute;
    right: 7px;
    bottom: 2px;
    font-size: 10px;
  }
}

.devices-table-container{
  @media screen and (max-width: 768px) {
    overflow-x:auto;
    > div{
      min-width: 600px;
    }
  }
}

.radio-container{
  >div{
    width: 155px;
  }
  >div:nth-child(2){
    width: 140px;
  }
  >div:nth-child(3){
    width: 280px;
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
    }
  }
  input{
    max-height: 37px;
    line-height: 1.2;
  }
  .d-flex{
    div{
      border: 1px solid #ced4da;
      min-height: 35px;
      display: flex;
      align-items: center;
      border-right: none;
      border-left: none;
    }
    input:first-child{
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input:last-child{
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  @media screen and (min-width: 768px) {
    > div:nth-child(3) {
      margin-top: -15px !important;
    }
  }
}

.tap-icon{
  width: 50px;
  height: 30px;
}

.orchard-sub-table-container{
  padding-right:0;
  padding-left: 0;
  tr{
    td{
      border: none;
      padding: 10px 0;
    }
  }
  .tap-icon{
    width: 60px;
    height: 30px;
  }
}

.notification-container{

  .notification-details-container {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    div {
      margin-right: 25px;

      div:first-child {
        color: #343A40;
        font-weight: 600;
      }

      div:last-child {
        color: #343A40;
      }
    }
  }

  .notification-table-container{
    margin-top: 0px;
  }
}

.index-card-container{
  margin-left: -15px !important;
  margin-right: -15px !important;
  @media screen and (min-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.health-container, .notification-container, .index-card-container{
  .rounded-pill{
    width: 80px;
    text-align: center;
  }
  .pill-info{
    color: #00B383;
    background-color: #00b38340;
  }
  .pill-warning{
    color: #F1B44C;
    background-color: #F1B44C2E;
  }
  .pill-danger{
    color: #F46A6A;
    background-color: #F46A6A2E;
  }
  .pill-success{
    background-color: #34C38F2E;
  }
}
.health-container{
  .rounded-pill {
    width: 90px;
    color: $primary;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.delete-modal-container {
  .modal-content{
    border: none;
    border-radius: 10px;
  }
  .delete-modal {
    height: 100px;
    position: relative;
    background-color: #2A3042;
    button span{
      background-color: #fff;
    }
    h5{
      color: #fff;
      font-size: 1.2rem;
    }
  }
  .btn-close{
    width: 0.6rem;
    color: #fff;
    background-size: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.drivers-table-container{
  tr{
    td:first-child{
      border-radius: 8px 0px 0px 8px;
      border-right: none;
    }

    td:nth-child(2){
      border-left: none;
      border-right: none;
    }

    td:last-child{
      border-radius: 0px 8px 8px 0px;
      border-left: none;
    }
  }
}

.driver-rule-modal-container{
  .modal-content{
    border-color: $dark;
  }
  .driver-rule-modal{
    height: 120px;
    position: relative;
    background-color: #556EE640;
    button span{
      background-color: #fff;
    }
    h5{
      color: #fff;
      font-size: 1.2rem;
      margin-left: auto;
    }
  }
  .btn-close{
    width: 0.6rem;
    color: #fff;
    background-size: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}


.editor-btn-container{
  div{
    cursor: pointer;
    text-decoration: underline;
  }
  div:first-child{
    color: #F46A6A;
  }

  div:nth-child(2){
    color: #556EE6;
  }
}
.ace_print-margin{display: none}

.dropdown-system{
  min-width: 200px;
  .dropdown-toggle {
    padding: 0;
    background-color: transparent !important;
    border: none;
    color: $dark !important;
  }
  input{
    width: 180px;
    margin: auto;
    margin-bottom: 10px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dropdown-menu{
    border: 1px solid #ced4da;
    padding-top: 0;
  }
}

.ace_editor, .ace_editor *{
  font-family: monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}

@keyframes showHide {
  0%   {opacity:0}
  30%  {opacity:0}
  100% {opacity:1}
}
@keyframes showHide2 {
  0%   {opacity:0}
  30%  {opacity:0}
  100% {opacity:1}
}


.img-upperview{
  animation-name: showHide2;
  animation-duration: 1.5s;
}
.img-sideview{
  animation-name: showHide;
  animation-duration: 1.5s;
}

.dashboard-container-plt{
  @media screen and (min-width: 768px) {
    .card{
      margin-bottom: 8px;
    }
    .card-plt{
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 7px;
    }
    .health-details-container{
      .text-start{
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      padding-bottom: 10px;
    }
    .notifi-item{
      padding-bottom: 25px !important;
    }
    .notifi-container-dashboard{
      margin-top: 14px;
      padding-bottom: 0px;
    }
    .padding-left-none{
      padding-left: 0;
    }
    .padding-right-none{
      padding-right: 0;
    }
    .card-first{
      padding-left: 4px;
      padding-right: 10px ;
    }
    .card-second{
      padding-right: 5px ;
    }
    .index-card-first{
      padding-left: 4px;
      padding-right: 7px ;
    }
    .index-card-last{
      padding-left: 10px;
      padding-right: 4px ;
    }
    .index-card-middle{
      padding-left: 9px;
      padding-right: 9px ;
    }
    .health-container{
      padding-bottom: 4px;
    }
    .card-user{
      padding-right: 9px;
      padding-left: 0;
    }
  }
}


@media screen and (min-width: 768px) {
  .user-card {
    height: 150px;
  }
  .alert-card {
    min-height: 300px;
    max-height: 300px;
    .verti-timeline{
      overflow-y: visible;
      max-height: 200px;
    }
  }
  .boat-card {
    min-height: 474px;
    max-height: 474px;
  }
  .health-card, .compare-card {
    min-height: 450px;
    max-height: 450px;
  }
  .index-card{
    min-height: 450px;
    max-height: 450px;
  }
  .index-card-small{
    min-height: 190px;
    max-height: 190px;
  }
  .temp-card, .overview-card, .sensor-card {
    min-height: 380px;
    max-height: 380px;
    @media screen and (min-width: 768px) {
      .table-overflow-custom {
        thead, tbody {
          display: block;
        }

        thead {
          min-width: 320px;

          th:first-child {
            width: 40%;
            min-width: 40%;
          }

          th:nth-child(2) {
            width: 40%;
            min-width: 40%;
          }
        }

        tbody {
          height: 270px;
          min-width: 320px;
          overflow-y: auto;
          overflow-x: hidden;

          td:first-child {
            min-width: 40%;
            width: 40%;
          }

          td:nth-child(2) {
            width: 40%;
            min-width: 40%;
          }
        }
      }
    }
  }

  .dashboard-container-plt {
    .user-card {
      height: 120px;
    }

    .alert-card {
      min-height: 317px;
      max-height: 317px;
    }

    .garden-card {
      min-height: 450px;
      max-height: 450px;
    }
  }
}

.sensor-card{
  @media screen and (min-width: 1150px) {
    .third-header{
      text-align: left;
    }
    .first-header {
      min-width: 150px !important;
    }
  }
}

.temprate-list{
  @media screen and (min-width: 768px) {
    max-height: 220px;
    padding-right: 8px;
    overflow-y: auto;
  }
}

.reset-button{
  background: none rgb(255, 255, 255);
  border: 0px;
  padding: 5px 10px;
  text-transform: none;
  appearance: none;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  overflow: hidden;
  margin-right: 1px;
  margin-bottom: 1px;
}

.fullscreen{
  overflow: auto;
}

.toast-info{
  div, svg{
    color: white !important;
  }
  svg {
    fill: white !important;
  }
  background-color: #556EE6 !important;
}

.toast-warning{
  div, svg{
    color: black !important;
  }
  svg {
    fill: black !important;
  }
  background-color: #F1B44C !important;
}

.toast-danger{
  div, svg{
    color: white !important;
  }
  svg {
    fill: white !important;
  }
  background-color: #F46A6A !important;
}

.sound-buttons button{
  display: none;
}



.image-gallery-image{
  max-height: calc(75vh - 80px) !important;
}

.btn-reset-default{
  @media screen and (min-width: 768px) {
    margin-left: 112px;
  }
}



/*************** Checkbox Additional State ******************/

.check-all-additional-state {
  background-color: #556ee6;
  border-color: #556ee6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cline x1='6' y1='10' x2='15' y2='10' stroke='%23fff' stroke-linecap='round' stroke-width='3'/%3e%3c/svg%3e");
}







.breadcrumb-item {
  white-space: nowrap;
  max-width: 43ch;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    max-width: 60ch;
  }
}